import $ from 'jquery'

$(document).ready( initForm )
if (window.acf) {
  let mktojs = document.createElement("script");
  mktojs.type = "text/javascript";
  mktojs.src = 'https://info.blend.com/js/forms2/js/forms2.min.js';
  document.body.appendChild(mktojs);
  window.acf.addAction('render_block_preview/type=form', initForm)
}

function initForm(){
  $('form.mktoForm').each(function () {
    let success_page = "";
    let form_id = $(this).data('form-id');
    let form = '#mktoForm_'+form_id;
    MktoForms2.loadForm("//info.blend.com", "287-UGB-469", form_id);
    MktoForms2.whenRendered(function (form) {
      var INPUTS_STOR = 'INPUT,SELECT,TEXTAREA',
        formEl = form.getFormElem()[0],
        reValidate = function (e) {
          /(^|\s)mktoInvalid(\s|$)/.test(this.className) && form.validate();
        },
        reValidateIfNonEmpty = function (e) {
          /(^|\s)mktoInvalid(\s|$)/.test(this.className) && this.value && form.validate();
        };
      [].forEach.call(formEl.querySelectorAll(INPUTS_STOR), function (el) {
        el.addEventListener('blur', reValidateIfNonEmpty);
      });
    });
    MktoForms2.whenReady(function (e) {
      for (var t = e.getFormElem()[0], o = document.querySelectorAll("#" + t.id + ", #" + t.id + " [style]"), r = 0, n = o.length; r < n; r++)o[r].removeAttribute("style"); var l = document.styleSheets; for (r = 0, n = l.length; r < n; r++) { var m = document.createElement("A"); m.href = l[r].href, -1 === m.hostname.search(/\.marketo\.com$/) && (l[r].ownerNode || l[r].owningElement).parentNode != t || (l[r].disabled = !0) }; let st = document.querySelector("div.success-text"); if (st && st.textContent.trim()) { e.onSuccess(function (values, followUpUrl) { e.getFormElem().hide(); st.style.display = ""; return !1 }) } else if (typeof success_page != "undefined" && success_page) { e.onSuccess(function (values, followUpUrl) { location.href = success_page; return !1 }) }; var s = document.querySelector("select"); s && (s.parentElement.className += " selectdiv");
      let formbtn = $(form).find('button[type="submit"]')
      $(formbtn).addClass('action primary');
    })
  });
  var invalidDomains = ["@gmail.", "@yahoo.", "@hotmail.", "@live.", "@aol.", "@outlook.", "@icloud.", "@company."];

  MktoForms2.whenReady(function (form) {
    form.onValidate(function () {
      if ( form.getId() !== 1871 ) {
        var email = form.vals().Email.toLowerCase();
        if (email) {
          if (!isEmailGood(email)) {
            form.submitable(false);
            var emailElem = form.getFormElem().find("#Email");
            form.showErrorMessage("Must be Business email.", emailElem);
          } else {
            form.submitable(true);
          }
        }
      }
    });
  });

  function isEmailGood(email) {
    for (var i = 0; i < invalidDomains.length; i++) {
      var domain = invalidDomains[i];
      if (email.indexOf(domain) != -1) {
        return false;
      }
    }
    return true;
  }
}
