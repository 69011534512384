import $ from 'jquery'
import 'slick-carousel'

$(document).ready( initTestimonialsSlider )
if (window.acf) {
  window.acf.addAction('render_block_preview/type=testimonials', initTestimonialsSlider)
}

function initTestimonialsSlider(){
  const sliderWrapper = $('.js-testimonials-slider');
  if (sliderWrapper.length) {
    sliderWrapper.not('.slick-initialized').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      arrows: !$('.page-template-page-careers').length,
      dots: true,
      nextArrow: '<button type="button" class="slick-next"><svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="48" y="48.5" width="48" height="48" rx="24" transform="rotate(-180 48 48.5)" fill="#0038FF"/><path d="M21.0264 17.4378L26.9735 24.5002L21.0264 31.5625" stroke="white" stroke-width="1.5"/></svg></button>',
      prevArrow: '<button type="button" class="slick-prev"><svg width="48" height="49" viewBox="0 0 48 49" fill="none" xmlns="http://www.w3.org/2000/svg"><rect y="0.5" width="48" height="48" rx="24" fill="#0038FF"/><path d="M26.9735 31.5622L21.0264 24.4998L26.9735 17.4375" stroke="white" stroke-width="1.5"/></svg></button>',
    });
  }
}
