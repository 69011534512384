import $ from 'jquery'

const $doc = $(document)

$(document).ready( initPostFuncs )

function initPostFuncs(){
  if ($('body.single-post .wp-block-post-time-to-read').length) {
    $('body.single-post .wp-block-post-time-to-read').text($('body.single-post .wp-block-post-time-to-read').text().replace('minutes', 'min read'));
  }
  if ($('.js-singlepost').length ) {
    $('.js-singlepost h2.wp-block-heading').each(function (index) {
      let h2 = $(this);
      let h2Text = h2.text();
      let h2Id = h2Text.toLowerCase().replaceAll(' ', '');
      if ( h2.attr('data-title') ) {
        h2Text = h2.attr('data-title');
      }
      h2Id = h2Id.replaceAll(/[^a-zA-Z0-9]/g, '');
      h2.attr('id', h2Id);
      $('.js-sticky-sidebar ul').append('<li><a href="#' + h2Id + '">' + h2Text + '</a></li>');
    });
    $doc.on('click', '.js-mobile-toc', toggleToC)
    function toggleToC() {
      let el = $(this);
      if ( el.attr('aria-expanded') == 'false' ) {
        el.attr('aria-expanded', true)
      } else {
        el.attr('aria-expanded', false)
      }
      $('#toc-list').slideToggle();
      el.toggleClass('active');
    }
  }
  if ( $('.post-item').length ) {
    $('.post-item__link').each(function() {
      let url = new URL($(this).attr('href'));
      if (url.hostname == 'play.vidyard.com') {
        let videoID = $(this).attr('href').split('/').pop().split('.')[0];
        let videoModal = '<div style="display: none;"><img class="vidyard-player-embed" data-uuid="' + videoID + '" data-v="4" data-type="lightbox" /></div>';
        $(videoModal).appendTo('body');
        $(this).on('click', function (e) {
          e.stopImmediatePropagation();
          e.preventDefault();
          let players = VidyardV4.api.getPlayersByUUID(videoID);
          let player = players[0];
          player.showLightbox();
          return false;
        });
      }
    });
  }
  $('.is-style-primary-button-arrow a').each(function() {
    $(this).append('<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.8906 6.96094L14.8359 8.03906L18.0469 11.25H3V12.75H18.0469L14.8359 15.9609L15.8906 17.0391L20.9297 12L15.8906 6.96094Z" fill="white"/></svg>');
  });
  $('.is-style-secondary-button-arrow a').each(function () {
    $(this).append('<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.8906 6.96094L14.8359 8.03906L18.0469 11.25H3V12.75H18.0469L14.8359 15.9609L15.8906 17.0391L20.9297 12L15.8906 6.96094Z" fill="#001356"/></svg>');
  });
  $('.is-style-tertiary-button-arrow a').each(function () {
    $(this).append('<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.8906 6.96094L14.8359 8.03906L18.0469 11.25H3V12.75H18.0469L14.8359 15.9609L15.8906 17.0391L20.9297 12L15.8906 6.96094Z" fill="#0038FF"/></svg>');
  });
  $('.is-style-text-button-arrow a').each(function () {
    $(this).append('<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.8906 6.96094L14.8359 8.03906L18.0469 11.25H3V12.75H18.0469L14.8359 15.9609L15.8906 17.0391L20.9297 12L15.8906 6.96094Z" fill="#9D005D"/></svg>');
  });
  $('.single-post__content iframe[src*="youtube.com"]').each(function () {
    $(this).wrap('<div class="iframe-wrapper"></div>');
  });
}
