import $ from 'jquery'
import 'slick-carousel'

$(document).ready( initFeaturesTabs )
if (window.acf) {
  window.acf.addAction('render_block_preview/type=features-tabs', initFeaturesTabs)
}

function initFeaturesTabs(){
  if ($('.js-features-tabs').length) {
    console.log('initFeaturesTabs')
    $('.js-features-tabs').slick({
      arrows: false,
      dots: true,
      slidesToShow: 1,
      fade: true,
      customPaging: function(slick,index) {
        console.log(slick.$slides.eq(index))
        var tabName = slick.$slides.eq(index).find('.tabs-item').attr('data-tab')
        return `<div class="category-button">${tabName}</div>`
    }
    });
  }
}
