import $ from 'jquery'

const $doc = $(document)

function toggleAccordionItem() {
  const $el = $(this);
  const $item = $el.closest('.js-accordion-item');
  if ($item.hasClass('active') ) {
    $el.attr('aria-expanded', 'false');
    toggleAccordionImage($el, false)
  } else {
    $el.attr('aria-expanded', 'true');
    toggleAccordionImage($el, true)
  }
  $el.parent().next().slideToggle('fast');
  $item.toggleClass('active');

  $('.js-accordion-item button').each(function(i, elm) {
    if ($el[0] !== elm) {
      toggleAccordionImage($(elm), false)
      $(elm).parent().next().slideUp('fast');
      $(elm).closest('.js-accordion-item').removeClass('active');
    }
  })
  
}

function toggleAccordionImage($el, bool) {
  const id = $el.closest('.js-accordion-item').data('title');
  if (!id) return false

  if (bool) {
    $(`.js-accordion-images #${id}`).addClass('is-active')
  } else {
    $(`.js-accordion-images #${id}`).removeClass('is-active')
  }
}

function triggerAccordionClick() {
  const $item = $(this).closest('.js-accordion-item')
  const button = $item.find('button')[0]
  const toggleAccordionWithButton = toggleAccordionItem.bind(button)

  toggleAccordionWithButton()
}

$doc.on('click', '.js-accordion-item button', toggleAccordionItem )
$doc.on('click', '.js-accordion-item .accordion__single-item-icon', triggerAccordionClick )
