import $ from 'jquery'

$(document).on('click', '#overlay-toggle', function (e) {
  $('body').toggleClass('overlay-on');
  $('#overlay-menu .menu-item-has-children.is-active').removeClass('is-active');
  e.preventDefault();
});

if ($('.hero--light-blue').length || $('.hero--dark-blue').length || $('.hero--oval-shape-blue').length || $('.page-template-page-newsletter').length) {
  $('#header-menu > .menu-item:last-child a').addClass('mg-button--tertiary');
}