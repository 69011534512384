import $ from 'jquery'

$("body").on("click", ".js-playvideo", function (e) {
  let wrap = $(this).parent();
  let video = $(this).parent().next('video');
  wrap.addClass('hidden');
  $(video).trigger('play');
});


jQuery(document).ready(function ($) {
  // Check if the '.dynamic-words-list div' exists
  if ($('.dynamic-words-list div').length > 0) {
    // Store all the div elements in a variable
    var words = $('.dynamic-words-list div');
    var wordIndex = 0;

    // Create an empty array to store all words
    var allWords = [];

    // Add all the dynamic words to the allWords array
    words.each(function () {
      allWords.push($(this).text());
    });

    // Get the initial text from the '.hero__title div' and add it to the end of the allWords array
    var initialText = $('.hero__title div').text();
    allWords.push(initialText); // Add initial text at the end of the array

    // Create a function to wrap each word in <span> tags
    function wrapWordInSpan(wordText) {
      var wordArray = wordText.split(' '); // Split words into an array
      return wordArray.map(function (word) {
        return '<span>' + word + '</span>'; // Wrap each word in a span
      }).join(' '); // Rejoin the words with a space
    }

    // Create a function to change the word with a fade-in effect
    function changeWord() {
      // Get the current word text from the array
      var wordText = allWords[wordIndex];

      // Fade out the current text, change it (wrapped in <span>), and fade it back in
      $('.hero__title div').fadeOut(400, function () {
        $(this).html(wrapWordInSpan(wordText)).fadeIn(400); // Change text and fade in
      });

      // Increment the wordIndex and reset if necessary
      wordIndex++;
      if (wordIndex >= allWords.length) {
        wordIndex = 0; // Reset to start over
      }
    }

    // Show the first word (from the dynamic words list) immediately on page load
    changeWord();

    // Start changing the words every 3 seconds, after a 3-second delay
    setTimeout(function () {
      setInterval(changeWord, 3000); // Change every 3 seconds
    }, 3000); // Delay of 3 seconds before the interval starts
  }
});
