import $ from 'jquery'

$(".js-toggle-rollover-card").on("click", function(e) {
  e.preventDefault();
  var content = $(this).parent().parent().parent().find(".rollover-cards__card__overlay");
  var outerContent = $(this).parent().parent();
  $(content).toggleClass('show');
  $(outerContent).toggleClass('set-height');
});


$(".rollover-cards__card__back").on("mouseleave", function () {
  var outerContent = $(this).parent();
  $(".rollover-cards__card__overlay").removeClass('show');
  $(".rollover-cards__card__back").removeClass('set-height');
});